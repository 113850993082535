import { useLastSeenBannerMutation } from '../../mutations';

export function useUpdateLastSeenRenewBanner() {
  const mutation = useLastSeenBannerMutation();

  const updateLastSeen = () => {
    mutation.mutate();
  };

  return {
    updateLastSeen,
    isUpdating: mutation.isPending,
    error: mutation.error,
  };
}
