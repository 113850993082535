import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { updateLastSeenRenewBanner } from '../api';
import type { UserDataResponse } from '@tn/shared';

export const useLastSeenBannerMutation = () => {
  const queryClient = useQueryClient();
  const { uid } = useUserState();

  return useMutation({
    mutationFn: async () => {
      if (!uid.value) {
        return;
      }

      return await updateLastSeenRenewBanner(uid.value);
    },
    onSuccess: (data) => {
      const previousData = queryClient.getQueryData<UserDataResponse>(['me']);

      if (previousData) {
        queryClient.setQueryData<UserDataResponse>(['me'], {
          ...previousData,
          ...(data?.now && {
            lastSeenRenewBannerMs: data.now,
          }),
        });
      }
    },
  });
};
