<script setup lang="ts">
import { watch } from 'vue';
import type { HeaderLink } from '~/types';
import { CartModal, PremiumRenewBanner } from '#components';
import { HEADERLINKS } from '~/constants/headerLinks';
import EVENTS, { SECTIONS } from '~/constants/events';
import { useTokens } from '~/features/tokens';
import { useLogoutMutation } from '~/features/auth';
import { useCart } from '~/features/cart';
import { useUserReviews } from '~/features/reviews';

defineOptions({
  inheritAttrs: false,
});

const { validTokenCount } = useTokens();
const { pendingReviewsAmount } = useUserReviews();
const { isEmailVerified, isBrand, email } = useUserState();
const { logout, logoutText, isLoggingOut } = useLogoutMutation();
const attrs = useAttrs();
const { isMobile } = useViewport();
const isHeaderDialogOpen = ref(false);
const links = computed(() => HEADERLINKS);

const modal = useModal();
const { $trackEvent } = useNuxtApp();

const { cartItemsCount } = useCart();
const { isActivePremium, shouldShowRenewBanner } = usePremiumCheck();

const route = useRoute();
const hasVerifiedEmailLocally = ref(false);

watch(
  () => route.fullPath,
  () => {
    isHeaderDialogOpen.value = false;
  }
);

const cartClicked = () => {
  if (!modal.isOpen.value) {
    modal.open(CartModal);

    $trackEvent(EVENTS.NAVIGATIONAL_CART_CLICKED, {
      page: route.meta.eventPageName,
      cart_product_count: cartItemsCount.value,
    });
  }
};

const linkClicked = (link: HeaderLink) => {
  const linkEventsMap: { [key: string]: string } = {
    Home: EVENTS.HOME_CLICKED,
    'Shop All': EVENTS.SHOP_ALL_CLICKED,
    'How it Works': EVENTS.HOW_IT_WORKS_CLICKED,
    Promotions: EVENTS.PROMOTIONS_CLICKED,
  };

  const eventProperties =
    link.label === 'Promotions'
      ? { page: route.meta.eventPageName, section: SECTIONS.PROMOTIONS_CTA }
      : { page: route.meta.eventPageName };

  $trackEvent(linkEventsMap[link.label], eventProperties);
};

const userInfoOpened = ref(false);

const userInfoIcon = computed(() => {
  return userInfoOpened.value ? 'i-heroicons-x-mark' : 'i-heroicons-user';
});

const auth = useFirebaseAuth();

const shouldShowVerifyEmailAlert = computed(() => {
  if (hasVerifiedEmailLocally.value) {
    return false;
  }

  return !isEmailVerified.value && isActivePremium.value;
});

const onVerify = async () => {
  await auth?.currentUser?.reload();

  if (auth?.currentUser?.emailVerified) {
    hasVerifiedEmailLocally.value = true;
  }
};

const shouldShowPopover = computed(() => {
  return shouldShowRenewBanner.value || !!pendingReviewsAmount.value;
});
</script>

<template>
  <header class="sticky top-0 z-20" v-bind="attrs">
    <div
      v-if="isBrand"
      class="flex items-center justify-center space-x-4 border-b border-black bg-white p-2 text-sm"
    >
      <strong
        class="inline-flex flex-shrink-0 items-center space-x-2 font-medium"
      >
        <UIcon name="i-heroicons-eye" class="h-5 w-5" />
        <span>Sampler View</span>
      </strong>
      <span class="truncate"
        >You are viewing the platform as a sampler and not all features will be
        available to you.</span
      >
    </div>

    <div class="max-w-app mx-auto w-full min-w-80 px-6 py-8 lg:px-0">
      <nav
        class="bg-beige-400 lg:px-25 relative flex h-12 items-center rounded-full border-b-4 border-l border-r border-t border-black px-2 py-2 md:py-4"
        aria-label="Global"
      >
        <div class="mr-3 flex flex-1 lg:ml-4 lg:mr-0">
          <div
            v-if="links?.length"
            v-bind="attrs"
            :class="[
              'bg-beige-300 absolute inset-x-0 top-full z-10 mx-6 mt-1 flex flex-col items-center justify-between gap-y-8 rounded-[20px] border-b-4 border-l border-r border-t border-black px-3 pb-3 pt-8 md:static md:m-0 md:flex md:flex-row md:gap-x-5 md:border-0 md:bg-transparent md:p-0 md:pl-4',
              { flex: isHeaderDialogOpen, hidden: !isHeaderDialogOpen },
            ]"
          >
            <div
              v-for="(link, index) of links"
              :key="index"
              class="relative mx-auto inline-flex"
            >
              <ULink
                :to="link.to"
                class="whitespace-nowrap text-sm font-bold transition hover:opacity-100 md:font-normal"
                inactive-class="opacity-70"
                active-class="opacity-100 !font-medium"
                @click="linkClicked(link)"
              >
                {{ link.label }}
              </ULink>
            </div>
            <div
              v-if="isMobile && email"
              class="border-tnNeutral-200 flex max-w-[290px] gap-2 overflow-hidden border-t px-1 pt-6"
            >
              <UIcon name="i-heroicons-user-circle" class="h-7 w-7" />
              <p
                class="overflow-hidden truncate text-ellipsis whitespace-nowrap"
              >
                {{ email }}
              </p>
            </div>
            <UButton
              color="secondary"
              variant="solid"
              size="sm"
              class="md:hidden"
              block
              :loading="isLoggingOut"
              @click="logout"
            >
              {{ logoutText }}
            </UButton>
          </div>
          <UButton
            v-if="links?.length"
            variant="link"
            color="black"
            class="md:hidden"
            :aria-label="`${isHeaderDialogOpen ? 'Close' : 'Open'} Menu`"
            :icon="
              isHeaderDialogOpen
                ? 'i-heroicons-x-mark-20-solid'
                : 'i-heroicons-bars-3-20-solid'
            "
            @click="isHeaderDialogOpen = !isHeaderDialogOpen"
          />
        </div>

        <div
          class="z-20 flex justify-center lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:transform lg:items-center"
        >
          <NuxtLink to="/" class="w-[7.5rem]">
            <img
              src="@/assets/taste-network-logo.svg"
              alt="Taste Network Logo"
              class="w-full object-contain"
            />
          </NuxtLink>
        </div>

        <div class="flex flex-1 items-center justify-end space-x-4">
          <UPopover
            :open="shouldShowPopover"
            :popper="{ arrow: true, placement: 'bottom', offsetDistance: 20 }"
            :ui="{
              background: 'bg-yellow-500',
              ring: 'ring-0 border border-b-4 border-r-4 border-black',
              shadow: 'shadow-none',
              rounded: 'rounded-lg',
              arrow: {
                background: 'before:bg-yellow-500',
                ring: 'before:ring-0 before:border before:border-b-0 before:border-black',
              },
            }"
          >
            <UChip
              size="3xl"
              :text="validTokenCount || pendingReviewsAmount || ''"
              :show="!!validTokenCount || !!pendingReviewsAmount"
              :color="validTokenCount ? 'blue' : 'red'"
            >
              <UButton
                color="secondary"
                variant="solid"
                size="sm"
                :to="{ name: 'wallet' }"
                :ui="{ padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' } }"
              >
                <TnTokenIcon size="xs" :disabled="!validTokenCount" />
                <span class="hidden lg:block">Wallet</span>
              </UButton>
            </UChip>

            <template #panel>
              <div
                v-if="pendingReviewsAmount"
                class="font-secondary p-3 text-xs font-semibold"
              >
                You have {{ pendingReviewsAmount }}
                {{ pendingReviewsAmount === 1 ? 'review' : 'reviews' }}
                to complete
              </div>
              <PremiumRenewBanner v-else />
            </template>
          </UPopover>

          <UChip
            size="3xl"
            :text="cartItemsCount"
            :show="!!cartItemsCount"
            color="blue"
          >
            <UButton
              color="secondary"
              variant="solid"
              size="sm"
              icon="i-heroicons-shopping-bag"
              :ui="{
                padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' },
                icon: { size: { sm: 'h-4 w-4' } },
              }"
              @click.stop="cartClicked"
            >
              <span class="hidden lg:block">Cart</span>
            </UButton>
          </UChip>
          <UPopover
            v-if="!isMobile"
            v-model:open="userInfoOpened"
            :popper="{
              placement: 'bottom',
              arrow: true,
              offsetDistance: 16,
            }"
            :ui="{
              ring: 'ring-0 border border-b-4 border-r-4 border-black',
              rounded: 'rounded-xl',
            }"
          >
            <UButton
              color="primary"
              variant="solid"
              :ui="{
                padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' },
                icon: { size: { sm: 'h-4 w-4' } },
              }"
              :icon="userInfoIcon"
              size="xs"
              class="p-2"
            >
            </UButton>

            <template #panel>
              <div
                class="flex max-w-[320px] flex-col gap-4 overflow-ellipsis p-4"
              >
                <div
                  v-if="email"
                  class="flex max-w-[320px] flex-row items-center space-x-2 overflow-ellipsis"
                >
                  <UIcon name="i-heroicons-user-circle" class="h-7 w-7" />
                  <UTooltip
                    :text="email"
                    :ui="{
                      wrapper: 'relative max-w-[200px] overflow-hidden',
                      base: 'h-auto px-2 py-1 text-xs text-center max-w-[320px] whitespace-normal break-words',
                    }"
                  >
                    <p class="truncate">
                      {{ email }}
                    </p>
                  </UTooltip>
                </div>
                <UButton
                  block
                  icon="i-heroicons-arrow-right-end-on-rectangle"
                  color="secondary"
                  :label="logoutText"
                  :loading="isLoggingOut"
                  @click="logout"
                >
                </UButton>
              </div>
            </template>
          </UPopover>
        </div>
      </nav>
    </div>
  </header>
  <div v-if="shouldShowVerifyEmailAlert">
    <VerifyEmailAlert @verify="onVerify" />
  </div>
</template>
